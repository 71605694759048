<template>
  <div style="height: 100%">
    <div style="margin-top: 0px; height: 100%; background: #000000;" class="table-box" ref="tableBox"
         @mouseenter="mouseenter" @mouseleave="mouseleave">
      <el-table :data="tableData" style="width: 100%;" :max-height="tableHeight+'px'" :header-cell-style="{background:'#000000 !important',color:'#FFFFFF',
       borderWidth: '0 0 0.5px 0 !important', borderColor: '#999999 !important'}"
                ref="mytable"
                :row-class-name="tableRowClassName"
                :cell-style="{color:'#FFFFFF'}">
        <el-table-column prop="personName" label="姓名" width="80" fixed />
        <el-table-column prop="deviceName" label="设备名" width="95" />
        <el-table-column prop="bodyTemperature" label="体温" width="80" />
        <el-table-column prop="eventCode" label="类型" width="100" >
          <template #default="scope">
            <p>{{ eventType(scope.row.eventCode) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="dateTime" label="时间" width="155" >
          <template #default="scope">
            <p>{{ datetime(scope.row.dateTime) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="result" label="结果" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button
                link
                type="primary"
                @click="itemClick(scope.row)"
                size="small">
              查看图片
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-image-viewer
        v-if="showViewer"
        @close="closeViewer"
        :infinite="false"
        :url-list="viewerList" />
  </div>
</template>

<script>
let that;
export default {
  name: "TongXing",
  data() {
    return {
      tableData:[],
      showViewer: false,  //大图
      viewerList:[],
      tableHeight: 1080,
      isScroll: true,
      refreshCount: 0
    }
  },
  created(){

    that = this;

  },
  mounted(){
    this.tongxing();

    window.addEventListener("resize", () => {
      this.tableHeight = window.innerHeight;
    })
    this.tableHeight = window.innerHeight

    // 拿到表格挂载后的真实DOM
   // const table = this.$refs.mytable;
   // console.log(this.$refs)
    // 拿到表格中承载数据的div元素
    const divData = this.$refs.mytable.$refs['bodyWrapper'];
    console.log(divData)
    // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    setInterval(() => {
      that.refreshCount = that.refreshCount + 1;
      if(that.refreshCount > 300){
        that.refreshCount = 0;
        that.tongxing();
      }

      if (!this.isScroll) return
      // 元素自增距离顶部1像素
      divData.scrollTop = divData.scrollTop+2;
      console.error(divData.scrollTop)
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (divData.clientHeight + divData.scrollTop >= divData.scrollHeight) {
        // 重置table距离顶部距离
        divData.scrollTop = 0;
      }
    }, 40);  // 滚动速度
  },
  methods:{
    mouseleave(){
      this.isScroll = true
    },
    mouseenter(){
      this.isScroll = false
    },
    itemClick(row){
      this.showViewer = true
      this.viewerList = [row.pictureURL]
    },
    closeViewer() {
      console.error("..............................")
      this.showViewer = false

    },
    datetime(time){
      if (time) return time.replace("T", " ")
      return time
    },
    eventType(code){
      switch (code) {
        case "10101":
          return "刷卡开锁";
        case "10102":
          return "刷卡加密码开锁";
        case "10103":
          return "刷卡加指纹开锁";
        case "10104":
          return "指纹开锁";
        case "10105":
          return "刷卡加指纹加密码开锁";
        case "10106":
          return "指纹加密码开锁";
        case "10107":
          return "人脸加指纹开锁";
        case "10108":
          return "人脸加密码开锁";
        case "10109":
          return "人脸加刷卡开锁";
        case "10110":
          return "人脸加密码加指纹开锁";
        case "10111":
          return "人脸加刷卡加指纹开锁";
        case "10112":
          return "工号加指纹开锁";
        case "10113":
          return "工号加指纹加密码开锁";
        case "10114":
          return "人脸开锁";
        case "10115":
          return "工号加人脸开锁";
        case "10116":
          return "户主开锁";
        case "10117":
          return "工号加密码开锁";
        case "10118":
          return "人证开锁";
        case "10119":
          return "蓝牙开锁";
        case "10120":
          return "密码开锁";
        case "10121":
          return "组合认证";
        case "10122":
          return "二维码开锁";
        case "10123":
          return "户主开锁";
        case "10124":
          return "中心平台开锁";
        case "10125":
          return "动态密码开锁";
        case "10126":
          return "人脸认证失败";
        case "10127":
          return "人证开锁失败";
        case "10128":
          return "身份证认证";
        case "10129":
          return "身份证认证失败";
      }
    },
    tongxing(){
      this.apiUtils.tongxing({communityId:"eb8573e79b5140d69aee27c7b4d56cd4", pageSize: 50}).then(res => {
        if (res.code == 200){
          this.tableData = res.data.list

        } else {
          this.$message.error("获取轨迹失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
    //标红table指定行
    tableRowClassName({row, rowIndex}) {

      //判断条件（quantityStock ，warningLimit ，quantityStock 列表字段信息）
      if (!row.bodyTemperature || parseFloat(row.bodyTemperature) > 37.3) {
        // 后面的css样式
        return 'warning-row';

      } else {
        // 后面的css样式
        return 'success-row';

      }

      // 默认标红第一行
      // if (rowIndex === 0) {
      //   return 'warning-row';
      // }

      return '';
    },

  }
}
</script>

<style scoped>
/*.table-box /deep/ .el-table--fit{
  border: 0 !important;
}*/

/*.table-box /deep/  .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border-width: 0px 0 0 0 !important;
  border-style: solid !important;
  border-color: #1f1f7e !important;
}*/

/*.table-box /deep/ .el-table tr {
  background-color: transparent!important;
  border: 0 !important;
  cursor: pointer;
}*/
.table-box /deep/  .el-table--enable-row-transition .el-table__body td{
  background-color: transparent;
  border-width: 0 0 0.5px 0 !important;
  border-style: solid !important;
  border-color: #999999 !important;
}

.table-box /deep/ .el-table__header-wrapper{
  background-color: transparent;
  border-width: 0 0 0px 0 !important;
  border-style: solid !important;
  border-color: #999999 !important;
}

.table-box /deep/ .el-table__fixed{
  background-color: transparent;
  border-width: 0 0 0px 0 !important;
  border-style: none !important;
  border-color: #999999 !important;
}

.table-box /deep/ .el-table__fixed-right::before,
.table-box /deep/ .el-table__fixed::before{
  height: 0px !important;
}


/deep/ .el-table .success-row {
  background: rgb(27, 33, 27) !important;
}

/deep/ .el-table .warning-row {
  background: #b93535 !important;
}

</style>